import {
  DIR_LTR,
  NAV_TYPE_SIDE,
  SIDE_NAV_LIGHT,
} from 'constants/ThemeConstant';

export const APP_NAME = 'Gading Murni';
// eslint-disable-next-line no-undef
export const API_BASE_URL = process.env.REACT_APP_API_URL;
export const APP_PREFIX_PATH = '/app';
export const AUTH_PREFIX_PATH = '/auth';
export const REDIRECT_URL_KEY = 'redirect';
export const AUTHENTICATED_ENTRY = `${APP_PREFIX_PATH}/dashboard`;
export const UNAUTHENTICATED_ENTRY = '/login';

export const THEME_CONFIG = {
  locale: 'id',
  mobileNav: false,
  headerNavColor: '',
  direction: DIR_LTR,
  blankLayout: false,
  navCollapsed: false,
  currentTheme: 'light',
  navType: NAV_TYPE_SIDE,
  topNavColor: '#3e82f7',
  sideNavTheme: SIDE_NAV_LIGHT,
};
