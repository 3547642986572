import { initReactI18next } from 'react-i18next';

import i18n from 'i18next';
import antdEnUS from 'antd/es/locale/en_US';
import antdIdID from 'antd/es/locale/id_ID';
import { THEME_CONFIG } from 'configs/AppConfig';

import en from './locales/en_US.json';
import id from './locales/id_ID.json';

export const resources = {
  id: {
    antd: antdIdID,
    translation: id,
  },
  en: {
    antd: antdEnUS,
    translation: en,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: THEME_CONFIG.locale,
  fallbackLng: THEME_CONFIG.locale,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
