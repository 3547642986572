import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Navigate, Routes as RouterRoutes } from 'react-router-dom';

import _ from 'lodash';
import { AUTHENTICATED_ENTRY } from 'configs/AppConfig';
import { publicRoutes, protectedRoutes } from 'configs/RoutesConfig';

import AppRoute from './AppRoute';
import PublicRoute from './PublicRoute';
import ProtectedRoute from './ProtectedRoute';

const Routes = () => {
  const roles = useSelector((state) => state?.auth?.user?.roles);
  const accessMenus = roles?.[0]?.access_menus;

  const getProtectedRoutes = () => {
    const newProtectedRoutes = [];
    _.forEach(protectedRoutes, (item) => {
      const name = item.key;
      const findMenu = accessMenus?.find(
        (x) => x.name === name || name.includes(`${x.name}-`)
      );
      if (item?.meta?.showAccess || findMenu) newProtectedRoutes.push(item);
    });
    return newProtectedRoutes;
  };

  const newProtectedRoutes = getProtectedRoutes();
  return (
    <RouterRoutes>
      <Route path="/" element={<ProtectedRoute />}>
        <Route
          path="/"
          element={<Navigate to={AUTHENTICATED_ENTRY} replace />}
        />
        {newProtectedRoutes.map((route, index) => {
          return (
            <Route
              element={
                <AppRoute
                  routeKey={route.key}
                  component={route.component}
                  {...route.meta}
                />
              }
              path={route.path}
              key={route.key + index}
            />
          );
        })}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Route>
      <Route path="/" element={<PublicRoute />}>
        {publicRoutes.map((route) => {
          return (
            <Route
              element={
                <AppRoute
                  routeKey={route.key}
                  component={route.component}
                  {...route.meta}
                />
              }
              key={route.path}
              path={route.path}
            />
          );
        })}
      </Route>
    </RouterRoutes>
  );
};

export default Routes;
