import axios from 'axios';
import { message } from 'antd';
import { API_BASE_URL } from 'configs/AppConfig';
import { onLogout } from 'store/slices/authSlice';
import { AUTH_TOKEN } from 'constants/AuthConstant';

import store from '../store';

const service = axios.create({
  timeout: 60000,
  baseURL: API_BASE_URL,
});

const TOKEN_PAYLOAD_KEY = 'authorization';

service.interceptors.request.use(
  (config) => {
    const jwtToken = localStorage.getItem(AUTH_TOKEN) || null;

    if (jwtToken) {
      config.headers[TOKEN_PAYLOAD_KEY] = `bearer ${jwtToken}`;
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// API respone interceptor
service.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    // let defaultErrorMessage = 'Internal Server Error';
    let defaultErrorMessage = 'Sedang Offline / Internet Putus';
    let errorMessage =
      error?.response?.data?.data_header?.message || defaultErrorMessage;

    if (error?.response?.status === 401) {
      errorMessage = 'Unauthorized';
      store.dispatch(onLogout());
    }

    if (error?.response?.status === 404) {
      errorMessage = 'Not Found';
    }

    if (error?.response?.status === 500) {
      // errorMessage = 'Internal Server Error';
      errorMessage = 'Sedang Offline / Internet Putus';
    }

    if (error?.response?.status === 508) {
      errorMessage = 'Time Out';
    }

    if (errorMessage) {
      message.error(errorMessage);
    }

    return Promise.reject(error);
  }
);

export default service;
