import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useGetMyProfile } from 'services/api/account';
import { onBlankLayout } from 'store/slices/themeSlice';

const AppRoute = ({ blankLayout, component: Component, ...props }) => {
  const token = useSelector((state) => state.auth?.user);
  const dispatch = useDispatch();

  useGetMyProfile({
    enabled: Boolean(token && !blankLayout),
  });

  useEffect(() => {
    const isBlank = blankLayout ? true : false;
    dispatch(onBlankLayout(isBlank));
  }, [blankLayout, dispatch]);

  return <Component {...props} />;
};

export default AppRoute;
