import { useDispatch } from 'react-redux';

import service from 'auth/FetchInterceptor';
import { setUser } from 'store/slices/authSlice';
import { useQuery, useMutation } from '@tanstack/react-query';

export const useGetMyProfile = ({ enabled } = {}) => {
  const dispatch = useDispatch();

  const response = useQuery({
    enabled,
    queryKey: ['get-my-profile'],
    queryFn: () => service.get(`/api/v1/admin/profile`),
  });

  if (response.data?.data_body?.user) {
    dispatch(setUser(response.data.data_body.user));
  }

  return response;
};

export const useUpdateMyProfile = ({ onError, onSuccess } = {}) =>
  useMutation({
    onError,
    onSuccess,
    mutationKey: ['update-my-profile'],
    mutationFn: ({ body }) => service.put(`/api/v1/admin/profile`, body),
  });
