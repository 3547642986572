import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeSwitcherProvider } from 'react-css-theme-switcher';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import store from './store';
import history from './history';
import Layouts from './layouts';
import mockServer from './mock';
import { THEME_CONFIG } from './configs/AppConfig';

import './lang';

const themes = {
  // eslint-disable-next-line no-undef
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  // eslint-disable-next-line no-undef
  light: `${process.env.PUBLIC_URL}/css/custom-light-theme.css`,
};

// eslint-disable-next-line no-undef
const environment = process.env.NODE_ENV;

if (environment === 'test') {
  mockServer({ environment });
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <BrowserRouter history={history}>
          <ThemeSwitcherProvider
            themeMap={themes}
            insertionPoint="styles-insertion-point"
            defaultTheme={THEME_CONFIG.currentTheme}
          >
            <QueryClientProvider client={queryClient}>
              <Layouts />
            </QueryClientProvider>
          </ThemeSwitcherProvider>
        </BrowserRouter>
      </Provider>
    </div>
  );
}

export default App;
