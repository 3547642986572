import Utils from 'utils';
import { createSlice } from '@reduxjs/toolkit';
import { AUTH_USER, AUTH_TOKEN } from 'constants/AuthConstant';

export const initialState = {
  token: localStorage.getItem(AUTH_TOKEN) || null,
  user: Utils.jsonParse(localStorage.getItem(AUTH_USER), null),
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    onLogout: () => {
      localStorage.clear();
      window.location.href = '/auth/login';
    },
    setUser: (state, action) => {
      state.user = action.payload;
      localStorage.setItem(AUTH_USER, JSON.stringify(action.payload));
    },
    onLoginSuccess: (state, action) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
      localStorage.setItem(AUTH_TOKEN, action.payload.token);
      localStorage.setItem(AUTH_USER, JSON.stringify(action.payload.user));
    },
  },
});

export const { setUser, onLogout, onLoginSuccess } = authSlice.actions;

export default authSlice.reducer;
